import React from "react"
// import Layout from "../components/layout"
import { Link } from "gatsby"
import get from "lodash/get"

class TagsTemplate extends React.Component {
  render() {
    // const post = get(this.props, "data.contentfulTaggedPages")
    const pageContext = get(this.props, "pageContext")
    const posts = pageContext.posts
    const blogPrefix = "/blog/"
    console.log(posts)

    return (
      <div>
        <p>Posts for the tag {pageContext.tagName}</p>
        <div><ul>
          {posts.map((post, index) => {
            return (
            <li key={index}><Link to={blogPrefix + post}>{post}</Link></li>
            )
          })}
        </ul></div>
      </div>
      
    )
  }
}

export default TagsTemplate